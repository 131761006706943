// import ButtonAppBar from "./Component/ResponsiveAppBar";

import {BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import ResponsiveBar from "./Component/ButtonAppBar";
// import Kgr from "./Pages/Kgr";
// import PaidKgr from "./Pages/PaidKgr";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import { Box } from '@mui/material/';
// import GoogleAutoSuggest from './Pages/GoogleAutoSuggest';
// import Contact from './Pages/Contact';
// import Allintitle from './Pages/Allintitle';
import TawkTo from 'tawkto-react';
import React, {useState, useEffect} from 'react';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
// import PeopleAlsoAsk from './Pages/PeopleAlsoAsk';
// import GoogleSuggestion from './Pages/GoogleSuggestion';
import PaidSuperFastKGR from './Pages/PaidSuperFastKGR';
// import IndexChecker from './Pages/IndexChecker';
// import BulkPageIndexChecker from './Pages/BulkPageIndexChecker';
// import GoogleScraper from './Pages/GoogleScraper';
// import BingScraper from './Pages/BingScraper';
// import RedditTool from './Pages/RedditTool';
// import Quora from './Pages/Quora';
// import KeywordBuilder from './Pages/KeywordBuilder';
// import Pricing from './Pages/Pricing';
// import BulkIndexer from './Pages/BulkIndexer';
// import GoogleQuora from "./Pages/GoogleQuora";
// import Seo from "./Pages/Seo";
// import SuperFastKGR from "./Pages/SuperFastKGR";
import Validate from './Pages/Validate';
import Footer from "./Component/Footer";
// import ProtectedRoutes from "./ProtectedRoutes";
// import axios from 'axios';




const theme = createTheme({
  palette: {
    primary: {
      main: '#FF00DF',
    },
  },
});



function App() {
  

  // const [isLogged, setIsLogged] = useState(false);
  const initializeState = () =>  !(localStorage.getItem("kgr"));

  const [tokens, setTokens] = useState(initializeState);



  

useEffect(() => {

   

  var tawk = new TawkTo('62ab69c17b967b117994ecb6', '1g5mpa8fp')

  tawk.onStatusChange((status) => 
  {
      // console.log(status)
  })

  if (localStorage.getItem("kgr") === null) {
    setTokens(false);
  } else {
    setTokens(true);
  }
}, []);


// const token = localStorage.getItem('token');

// useEffect(() => {

//   if(token && token.length > 8){
   
//     setIsLogged(true)
//   }
// }, [])



//   



  return (
    <ThemeProvider theme={theme}>
    <Router>
    <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-between', maxHeight: 'md'  }} className="bigContainer">
      <Box>
      <ResponsiveBar tokens={tokens} />
        {/* <ButtonAppBar  /> */}
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Routes>
          {/* <Route exact path="/" element={< Kgr />} /> */}
          {/* <Route exact path="/" element={tokens ?  <PaidKgr /> : <Kgr /> } />  */}
          <Route path="/" element={tokens ? <PaidSuperFastKGR /> : <Navigate to="/signup" />} /> 
           {/* <Route path="/youtube-auto-complete" element={<GoogleAutoSuggest />} /> */}
           {/* <Route path="/contact" element={<Contact />} /> */}
           {/* <Route path="/premium" element={<PaidKgr />} /> */}

           <Route path="/login" element={<Login />} />
           <Route path="/validate" element={<Validate />} />
           <Route path="/signup" element={<SignUp />} />
           {/* <Route path="/newkgr" element={<SuperFastKGR />} /> */}
           {/* <Route path="/newpaidkgr" element={< />} /> */}
           {/* <Route path="/people-also-ask" element={<PeopleAlsoAsk />} />
           <Route path="/google-suggestion" element={<GoogleSuggestion />} /> */}
           
          
           
           {/* {/* <Route path="/bulk-website-pages-index-checker" element={tokens ? <IndexChecker /> : <Navigate to="/signup" />} />  */}
           {/* <Route path="/bulk-link-index-checker" element={tokens ? <BulkPageIndexChecker /> : <Navigate to="/signup" />} />  */}
           {/* <Route path="/google-scraper" element={tokens ? <GoogleScraper /> : <Navigate to="/signup" />} /> 
           <Route path="/bing-scraper" element={tokens ? <BingScraper /> : <Navigate to="/signup" />} /> */} */}
           {/* <Route path="/quora-question-finder" element={tokens ? <GoogleQuora /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/pricing" element={tokens ? <Pricing /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/bulk-link-indexer" element={tokens ? <BulkIndexer /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/reddit-topic-hunter" element={tokens ? <RedditTool /> : <Navigate to="/signup" />} /> */}
           {/* <Route path="/reddit-topic-hunter" element={<RedditTool />} /> */}
           {/* <Route path="/seo" element={<Seo />} /> */}
           {/* <Route path="/quora" element={<Quora />} /> */}
           
         
           {/* <Route path="/keyword-builder" element={<KeywordBuilder />} /> */}

           {/* <Route path="/bulk-website-pages-index-checker" element={<IndexChecker />} />
           <Route path="/bulk-pages-index-checker" element={<BulkPageIndexChecker />} />
           <Route path="/google-scraper" element={<GoogleScraper />} />
           <Route path="/bing-scraper" element={<BingScraper />} /> */}
          {/* <Route path="/pricing" element={<Pricing />} /> */}
      
        </Routes>
        {/* </Routes> */}
        </Box>
      <Box>
        <Footer />
        
        </Box>
        
        </Box>
      
        </Router>
        </ThemeProvider>
  );
}

export default App;
