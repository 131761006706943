import React from 'react'
import {  Link } from "react-router-dom";
import {Grid} from "@mui/material"

function Validate() {
  return (
    <div>
          <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeadingvalidate">You are logged in. <Link to="/">Click here</Link> to go to the Home Page. </h2>
         {/* <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box> */}
          
        </Grid>
        <p></p>
    </div>
  )
}

export default Validate